.container {
    display: flex;
    align-items: center;
    height: 100vh;
    margin: auto;
    justify-content: center;
}
pre {
    background-color: transparent !important;
    font-size: 14px;
}

h1 {
    font-size: 62px;
    font-weight: 400;
}
h2 {
    font-size: 32px;
    color: var(--secondary-3);
    font-weight: 300;
}
p {
    font-size: 18px;
}
.code-snippet {
    height: 100%;
    width: 45vw;
}
.game {
    width: 36vw;
    margin-left: 35px;
}
@media screen and (max-width: 600px) {
    h2 {
        color: var(--accent-2);
        font-size: 20px;
        margin-bottom: 20vw;
    }
    h1 {
        line-height: 1;
    }
    .container {
        justify-content: normal;
        padding: 0 5px;
        height: 100vh;
    }
    body {
        padding-left: 1vw;
    }
}