.ctx {
    height: 100vh;
    width: 243px;
    border-right: 1px var(--grey) solid;
    margin: 0;
    padding: 0;
}
.Contact-Button {
    border: unset;
    background-color: transparent;
    width: 100%;
    border-bottom: 1px var(--grey) solid;
    color: white;
    font-family: 'Fira Code', monospace;
    font-weight: 400;
    font-size: 12px;
    padding: 8px 0;
    transition: all .5s linear;
}
.Contact-Button:hover {
    cursor: pointer;
}
.Arrow{
    transition: all 259ms linear;
    margin-right: 10px;
}
.Arrow.Selected {
    transition: all 250ms linear;
    transform: rotate(-90deg);
}
.elem-button {
    color: var(--secondary-1);
    text-decoration: none;
    font-size: 11px;
    transition: all 250ms linear;
}
.elem-button:hover {
    color: white;
}
.elem-container {
    width: 243px;
    display: flex;
    justify-content: flex-start;
    margin: 2vh 0vh 0 5px;
}
.icon{
    margin-right: 5px;
    margin-left: 5px;
    transition: all 250ms linear;
}
.border-top {
    border-top: 1px var(--grey) solid;
}
.open {
    height: unset !important;
}   
.small {
    aspect-ratio: 1/1;
    width: 13px;
}