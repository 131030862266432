.game-container {
    background: linear-gradient(150.26deg, rgba(23, 85, 83, 0.7) 1.7%, rgba(67, 217, 173, 0.091) 81.82%);
    height: 450px;
    position: relative;
    border-radius: 10px;
    border: 1px solid black;
    display: flex;
    padding: 50px;
}
.GameOver {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--secondary-2);
    background-color: var(--primary-2);
    width: 100%;
    text-align: center;
    height: 45px;
    line-height: 2.5;
    font-weight: bold;
}
.restart-button {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--secondary-1);
    font-size: medium;
    font-weight: 600;
    margin-top: 10px;
}
.restart-button:hover {
    color: white;
}