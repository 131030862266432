.container-about {
    display: flex;
}
.button-unset {
    background-color: unset;
    border: unset;
    cursor: pointer;
    display: flex;
}
.context-menu {
    width: 85vw;
    border-bottom: var(--grey) 1px solid;
    margin: 0;
    padding: 0;
    display: flex;
}
.element-about {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0.8vh 0;
    width: 160px;
    border-right: var(--grey) 1px solid;
    color: var(--secondary-1);
    padding-left: 1vw;

}
.close-button:hover {
    color: white;
}
.about-container {
    height: 85vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.information {
    width: 40%;
    height: 100%;
    border-right: var(--grey) 1px solid;
}
.showcase {
    width: 60%;
    height: 100%;
}
.info {    
    padding: 40px;
    margin: 0;
    white-space: pre-wrap;
    color: var(--secondary-1);
}
@media screen and (max-width: 600px) {
    .container-about {
        flex-direction: column;
    }
    .abt {
        height: auto !important;
        width: 100vw;
    }
    .about-container {
        flex-direction: column;
    }
    .information {
        width: auto;
        height: auto;
    }
    .showcase {
        width: 100vw;
        height: auto;   
        margin-left: 30px;
    }
    .avatar-container {
        width: 100vw;
        justify-content: center;
    }
    .title {
        font-size: 15px;
        margin: 10vw 2vw;
        color: white;
        text-align: start;
    }
    .Contact-Button {
        background-color: var(--grey) !important;
        margin-bottom: 5px;
    }
    .context-menu {
        border: none !important;
    }
}